import {useReactiveVar} from "@apollo/client";
import {authPhoneLogin, loginFallbackCallback, showAuth, authStage} from "../../config/react.vars";
import React, {useEffect, useState} from "react";
import AppAuthLoginFormModal from "./app.auth.loginform.modal";


const AppAuthModalPopup = () => {
    let has401 = useReactiveVar(showAuth);
    let phoneStart = useReactiveVar(authPhoneLogin);
    let popupAuthStage = useReactiveVar(authStage);
    const authCB = useReactiveVar(loginFallbackCallback);

    const [showLogin, setShowLogin] = useState(false);
    const toggleLogin = (synteticCall) => {
        
        if(synteticCall === undefined){
            synteticCall = false;
        }

        setShowLogin(!showLogin);

        if(!showLogin){
            showAuth(false);
        }

        if(!synteticCall){
            // стереть установленный колбэк
            console.log('set callback to null');
            /*
            if(authCB){
                authCB.callback = null;
                loginFallbackCallback(authCB);
            }else{
                loginFallbackCallback({callback:null});
            }*/
        }

    }


    useEffect( () => {
        //console.log('USE EFFECT AUTH POPUP',has401);
        //console.log('USE EFFECT AUTH POPUP 2 ', showAuth() );
        if((has401) && (has401 !== showLogin)){
            toggleLogin(true);
        }
    },[has401]);



    /*console.log('****** AUTH_CALLBACK ******');
    console.log(showAuth());
    console.log("****************************");
    */



    return (

        <AppAuthLoginFormModal show={showLogin} phoneStart={phoneStart} toggle={toggleLogin} stage={popupAuthStage} className="loginform" />
    )
};


export default AppAuthModalPopup;