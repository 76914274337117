import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import Main from './components/main';
import { ApolloProvider, useReactiveVar } from '@apollo/client';
import { AuthProvider, useAuthState } from '../../auth/app.auth.context';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { clientPrivate } from '../../../config/apollo_client';
import AppAuthModalPopup from '../../auth/app.auth.modalpopup';

const AppHeaderNavButtons = () => {
    // оболочка для колбэка который запишется в toggleAuth
    const [ loginCB, setLoginCB ] = useState({ toggleAuth: null });

    useEffect(() => {
        let nodeList = document.getElementsByName('toggle_auth');

        if (nodeList?.length > 0) {
            let node = nodeList.item(0);

            if (node?.content === '1') {
                if (loginCB.toggleAuth) {
                    loginCB.toggleAuth();
                }
            }
        }
    });

    return (
        <ApolloProvider client={clientPrivate}>
            <AuthProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/*" element={<Main login_cb={loginCB}/>}/>
                    </Routes>
                </BrowserRouter>
                <AppAuthModalPopup/>
            </AuthProvider>
        </ApolloProvider>
    );
};

const AppHeader = (<AppHeaderNavButtons/>);

const el = document.getElementById('app_header_nav_buttons');

if (el) {
    const root = createRoot(el);
    root.render(AppHeader);
}
